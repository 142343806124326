<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-store-alt"></i> ข้อมูลผูกซัพพลายเออร์กับร้านค้า </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">
          <pageStoreMappingSupplier />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import pageStoreMappingSupplier from "./view/page.store_mapping_supplier.vue";

  export default {
    name: 'store_mapping_supplier-view-page-store_mapping_supplier',
    components: {
      pageStoreMappingSupplier
    }
  };
</script>
<style>

</style>
