<template>
  <div>
    <div class="pb-4"></div>
    <div>
      <div class="card custom-card">
        <div class="card-body">
          <div class="mb-4">
            <b-form-checkbox
              v-model="showSelectedStore"
              @change="checkSelectedStoreHandler()"
              >แสดงเฉพาะซัพพลายเออร์ที่ผูกกับร้านที่เลือก</b-form-checkbox
            >
          </div>

          <div
            class="table-responsive frozen-table"
            data-table="both"
          >
            <table class="table align-middle mb-3 table-bordered">
              <thead>
                <tr class="text-center first">
                  <th rowspan="2" style="vertical-align: middle; z-index: 999;">
                    ชื่อซัพพลายเออร์
                  </th>
                  <th colspan="9"><span>ชื่อร้านค้า</span></th>
                </tr>
                <tr class="text-center second">
                  <th
                    v-for="store in items && items.length > 0
                      ? items[0].stores
                      : []"
                    :key="`mappint-store-name-${store.id}`"
                  >
                    <span>{{ store.name }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="data in items"
                  :key="`store-mapping-supplier-${data.id}`"
                >
                  <th
                    :style="!data.highlight ? 'color: #848484;' : ''"
                    class="freeze-column-table"
                  >
                    <span>{{ data.name }}</span>
                  </th>
                  <td
                    class="text-center checkbox_style"
                    v-for="store in data.stores"
                    :key="`store-mapping-store-${data.id}-${store.id}`"
                  >
                    <span><b-form-checkbox
                      :name="`checkbox_store${store.id}`"
                      v-model="store.selected"
                    /></span>
                  </td>
                </tr>
                <tr v-if="items.length === 0" class="text-center">
                  <td :colspan="2"><span>ไม่มีข้อมูล</span></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="padding_top_20 text-right">
            <button
              type="button"
              @click="saveHandler()"
              class="btn btn-primary style_btn"
              v-if="permission.actionData.flag_update"
            >
              <i class="fa fa-check"></i> บันทึก
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import supplier from "../../supplier/mock/supplier";
export default {
  name: "store-view-page-store",
  data() {
    return {
      permission: {
        appSlug: "store-mapping-supplier",
        actionData: {},
      },
      selected: [],
      defaultItems: [],
      items: [],
      showSelectedStore: false,
    };
  },
  methods: {
    async saveHandler() {
      const params = { mappings: JSON.stringify(this.defaultItems) };
      this.SpinnerService.showLoader();
      const result = await this.HttpServices.postData(
        `/ab_store_supplier`,
        params
      );
      if (result && result.status.code == "200") {
        this.AlertUtils.alert("success", `บันทึกสำเร็จ`);
        this.getSuppliers();
      } else {
        this.AlertUtils.alert("warning", result.status.message);
      }
      this.SpinnerService.hideLoader();
    },
    async getSuppliers() {
      this.SpinnerService.showLoader();
      const result = await this.HttpServices.getData(`/ab_store_supplier`);
      if (result && result.status.code == "200") {
        const data = result.data;
        this.items = data;
        this.defaultItems = JSON.parse(JSON.stringify(this.items));
        this.checkSelectedStoreHandler();
      } else {
        this.items = [];
      }
      this.SpinnerService.hideLoader();
    },
    checkSelectedStoreHandler() {
      if (this.showSelectedStore) {
        const suppliers = [];
        for (const v of this.defaultItems) {
          let selected = false;
          for (const vv of v.stores) {
            if (vv.selected) {
              selected = true;
              break;
            }
          }
          if (selected) {
            suppliers.push(v);
          }
        }
        this.items = suppliers;
      } else {
        this.items = this.defaultItems;
      }
    },
    selectedStore(value, obj) {
      console.log("selectedStore ", value, obj);
      if (value) {
        this.setSelectedColumn(obj);
      } else {
        console.log("selected ", this.selected);
        const index = this.selected.indexOf(obj);
        if (index > -1) {
          this.selected.splice(index, 1);
        }
        console.log("selected 1", this.selected);
      }
    },
    setSelectedColumn(obj) {
      this.selected.push(obj);
      console.log("selected ", this.selected);
    },
    changePage(page) {
      let params = {
        page: page,
        limit: this.paging.limit,
      };
      console.log("changePage ", page, params);
    },
  },
  async mounted() {
    await this.PermissionServices.viewPermission(
      this.permission.appSlug,
      this.$router
    );
    this.permission.actionData = await this.PermissionServices.getPermission(
      this.permission.appSlug
    );
    await this.getSuppliers();
  },
};
</script>
<style scope>
.checkbox_style > .custom-control {
  left: 12px;
}
.freeze-column-table {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0;
  left: -1px !important;
  background-color: rgb(255, 255, 255) !important;
  border: 1px solid #e9ecef !important;
  z-index: 999;
  width: 25rem !important;
}
.freeze-row-table {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: -1px;
  left: 0 !important;
  background-color: rgb(255, 255, 255) !important;
  border: 1px solid #e9ecef !important;
  z-index: 999;
}
</style>

<style lang="scss" scoped>
/* Colors */
$primary-highlight: #fff;
$primary-background: #fff;
$corner-color: #fff;
$gray-color: #e9ecef;

$thead-background: $primary-highlight;
$thead-color: $primary-background;
$table-border: $gray-color;

/* Bidirectional Table */
div.frozen-table {
  width: 100%;
  min-height: 150px;
  overflow: scroll;
  margin: 0 auto;

  /* Remove the below 3 properties on your Website (Test-Purpose) */
  width: 70vw;
  height: 60vh;
  resize: both;

  table {
    min-width: 100%;
    min-height: 100%;
    border-collapse: collapse;

    th {
      height: 100%;
      // border: 0px none transparent;
              border: 1px solid $table-border !important;
      padding: 0;

      span {
        display: block;
        padding: 20px;
        border-right: 1px solid $table-border;
      }
    }

    thead {
      tr {
        th {
          background: $thead-background;
          // color: $thead-color;

          position: relative;
          z-index: 0;
        }

        &:first-child {
          th {
            span {
              border: 0px none transparent;
              // border: 1px solid $table-border !important;
            }

            &:first-child {
              background: $thead-background;
            }
          }
        }
      }

      .first th {
        position: sticky;
        top: -2px;
        z-index: 30;
      }

      .second th {
        position: sticky;
        top: 3.724rem;
        z-index: 20;
      }
    }

    tbody {
      th {
        background: $primary-background;

        &:only-child {
          z-index: -1;

          span {
            border: 0px none transparent;
            border-top: 1px solid $table-border;
            border-bottom: 1px solid $table-border;
          }
        }
      }

      td {
        background-color: $primary-background;

        padding: 20px;
      }
    }
  }

  &[data-table="top"],
  &[data-table="both"] {
    thead {
      th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
      }
    }
  }

  &[data-table="left"],
  &[data-table="both"] {
    tbody {
      th {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        z-index: 1;
      }
    }

    thead {
      th {
        &:first-child {
          position: -webkit-sticky;
          position: sticky;
          left: 0;
          z-index: 30;
        }
      }
    }
  }
}
</style>
