export default {
  supplier: {
    rows: [{
      id: 1,
      name: 'บริษัท ดอกไม้',
      tax_id: '12121212',
      tax_name: 'นาย A',
      tax_address: '',
      created: '',
      updated: '',
      contact: 'นายเก่ง',
      phone: '081-633-0101',
      email: 'flower@gmail.com',
      line: '@flower',
      address_id: '',
      full_address: '',
      remark: '-',
      status: '',
      tier: ''
    }, {
      id: 2,
      name: 'บ้านสวน',
      tax_id: '4545454',
      tax_name: 'นาย A',
      tax_address: '',
      created: '',
      updated: '',
      contact: 'ทองแดง',
      phone: '081-010-0101',
      email: 'fxdd@gmail.com',
      line: '@flower34',
      address_id: '',
      full_address: '',
      remark: 'อยู่หลังดอนเมือง',
      status: '',
      tier: ''
    }],
    paging: {
      rowTotal: 1
    }
  },
  search_filters: {
    name_filters: [{
      filter_count: 0,
      id: 1,
      name: 'test company'
    }]
  }
}
